<template>
  <div class="menu">
    <div
      class="menu__activator"
      @click.prevent.stop="isShow = !isShow"
      ref="activator"
    >
      <slot
        class="menu__icon"
        v-if="$slots.activator"
        name="activator"
      />
      <r-icon
        v-else
        class="menu__icon"
        fill="rocky"
        icon="menu-context"
      />
    </div>
    <div
      v-show="isShow"
      class="menu__list"
      style="margin-top: 10px;width: 140px;right: 0"
      @click="isShow = !isShow"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      default: () => ({
      })
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  mounted() {
    addEventListener('click', this.onMouseClick);
  },
  beforeDestroy() {
    removeEventListener('click', this.onMouseClick);
  },
  methods: {
    onMouseClick(e) {
      if (e.target === this.$refs.activator) {

      } else {
        this.isShow = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  position: relative;
  &__activator {
    cursor: pointer;
    margin-top: 50%;
  }
  &__icon {
    pointer-events: none;
  }
  &__list {
    position: absolute;
    background: #fff;
    box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
    border-radius: 8px;
    padding: 24px 20px;
    z-index: 10;
  }
}
</style>
