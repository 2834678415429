<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div
          @click="upload = !upload"
          style="width: 32px;"
          class="mr-4 ml-auto"
        >
          <r-icon
            class="mr-2 icon__close"
            icon="close"
            fill="rocky"
            size="20"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <div
              style="display: table;"
              class="mb-6"
            >
              <r-icon
                class="mr-2 margin-right"
                icon="warning"
                size="56"
                fill="fargo"
              />
            </div>
            <h1 class="ricotta mb-1">
              {{ this.organizationIds != null ? 'Удалить сводку по учреждению за ' + json.dateInfo + '?' : 'Удалить учреждение?' }}
            </h1>
            <div class="date">
              Это действие невозможно будет отменить
            </div>
            <div class="flex mt-2 mb-4">
              <r-button
                class="flex mt-8 mr-4"
                style="background: #E4EDFB;color: #214EB0;"
                @click="Close"
                type="secondary"
                width="wide"
                title="Не удалять"
              />
              <r-button
                class="flex button_too mt-8"
                @click="DeleteObj"
                width="wide"
                title="Да, удалить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'Delete',
  components: {
  },
  data() {
    return {
      upload: false,
      id: null,
      organizationIds: null,
      delete: null,
      json: null
    };
  },
  created() {
    this.$parent.$on('updateDelete', this.uploadContainerDel);
  },
  methods: {
    Close() {
      this.upload = false;
    },
    uploadContainerDel(json) {
      this.upload = true;
      this.id = json.id;
      if (json.delete) {
        this.organizationIds = json.organizationIds;
        this.json = json;
      }
    },
    DeleteObj() {
      const _this = this;
      const api = new Api();
      if (this.organizationIds) {
        api.getDeleteIds(this.organizationIds, this.json.dateInfo).then(result => {
          const r = this.$router.resolve({
            name: 'index',
            params: {}
          });
          if (!result?.error) {
            window.location.assign(r.href);
          }
        }, error => {
          console.error(error);
        });
      } else {
        api.getDelete(_this.id).then(result => {
          const r = this.$router.resolve({
            name: 'index',
            params: {},
            query: { q: 'institution' }
          });
          if (!result?.error) {
            window.location.assign(r.href);
          }
        }, error => {
          console.error(error);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.48;
}

.align-center {
  align-items: center;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 33%;
  margin-left: 31%;
}
.margin-right {
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

.notSave {
  width: 100%;
  display: inline-block;
}
.objects {
  &__filter {
    display: flex;
  }
}
.icon__close {
  cursor: pointer;
  top: 12px;
  right: 2px;
  position: absolute;
}
</style>
