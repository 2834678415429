<template>
  <div class="objects button_fly">
    <admin-header
      title="COVID"
      :back="false"
    />
    <r-tabs
      class="mt-7 mb-6"
      :items="tabs"
      v-model="activeTabId"
      @input="clickTab"
    />
    <div
      class="flex mb-6"
      v-if="activeTabId.id === 'covid'"
    >
      <router-link
        class="flex align-center sulguni rocky--text mr-6"
        to="new"
      >
        <r-icon
          class="mr-2"
          icon="add"
        />
        Добавить сводку
      </router-link>
    </div>
    <div
      class="flex mb-6"
      v-if="activeTabId.id === 'institution'"
    >
      <router-link
        class="flex align-center sulguni rocky--text mr-6"
        to="new-institution"
      >
        <r-icon
          class="mr-2"
          icon="add"
        />
        Добавить учреждение
      </router-link>
    </div>
    <div class="flex mb-6">
      <r-date-picker
        click-close
        label="Дата"
        class="flex-1 mr-4"
        :is-clear-model="true"
        v-if="activeTabId.id === 'covid'"
        v-model="filterStartDate"
        @input="dateFunc"
      />
      <r-input
        before-icon="search"
        class="flex-1 mr-6"
        label="Поиск"
        v-model="search"
        @change="searchFunc"
      />
      <r-button-simple
        v-if="activeTabId.id != 'covid'"
        size="larishae"
        icon="icon-view"
        icon-size="20"
        :key="'btn_'+count"
        :type="selectedView == 'map' ? 'secondary' : 'primary'"
        @click="mapClick('table')"
      />
      <r-button-simple
        v-if="activeTabId.id == 'covid'"
        size="larishae"
        icon="icon-view"
        icon-size="20"
        :key="'btn_'+count"
        @click="mapClick('table')"
        :type="'primary'"
      />
      <r-button-simple
        v-if="activeTabId.id != 'covid'"
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :key="'btn1_'+count"
        :type="selectedView == 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="mapClick('map')"
      />
    </div>
    <loader v-if="isLoading" />
    <not-found
      v-else-if="!isLoading && (activeTabId.id === 'covid' && filteredList.length == 0 )
        || (objectOrg.length == 0 && activeTabId.id == 'institution' && selectedView === 'table')"
    />
    <div v-else>
      <template>
        <div class="flex mb-4 align-center">
          <div class="ml-auto parmigiano text-titanic opacity-48">
            {{ !!searchText || selectedDate != '1970-01-01' ? 'Найдено' : 'Всего' }}
            {{ activeTabId.id == 'institution' ? countOrg : countObjects }}
          </div>
        </div>
      </template>
      <drag-drop-table
        :search="search"
        v-if="activeTabId.id == 'institution' && selectedView === 'table'"
        :list-item="objectOrg"
      />
      <tab-list
        :search="search"
        :key="count"
        :items="filteredList"
        :selected-date="countSelect"
        :date-info="selectedDate && selectedDate != '1970-01-01' ? selectedDate : formatDate(new Date())"
        v-if="activeTabId.id === 'covid'"
      />
      <div
        v-if="activeTabId.id !== 'covid' && selectedView === 'map'"
        class="objects__map"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          :key="count"
          show-type
          search
          @input="searchAddress"
          :value="searchText"
          @click="onMapClick"
          @mapInit="initMap"
          @markersWasAdd="markersWasAdd"
          :balloon-component="BalloonCard"
          :ball="true"
        >
          <div v-if="objectOrg?.length > 0">
            <ymap-marker
              v-for="(marker,index) of objectOrg"
              v-if="marker.lat"
              :key="`_${marker.id}+${index}`"
              :coords="[marker.lat, marker.lng]"
              :marker-id="`marker_${marker.id}+${index}`"
              :balloon="{header: {id: marker.id, name: marker.title}}"
              :icon="$markerIconPoint()"
              :options="{
                hideIconOnBalloonOpen: false,
              }"
              :balloon-component-props="{
                marker: {
                  ...marker,
                  id: marker.id
                },
              }"
            />
          </div>
        </rir-map>
      </div>
    </div>
    <model />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import RirMap from '@/components/RirMap';
import BalloonCard from '@/components/BalloonCard';
import Model from '@/components/Model.vue';
import DragDropTable from '@/components/DragDropTable.vue';
import notFound from '@/components/NotFound.vue';
import Loader from '@/components/Loader.vue';
import TabList from '../components/TabList';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    BalloonCard,
    AdminHeader,
    TabList,
    RirMap,
    Model,
    DragDropTable,
    notFound
  },
  data() {
    return {
      clickMap: false,
      activeTabId: {
        id: 'covid',
        title: 'Сводки'
      },
      count: 0,
      tabs: [
        {
          id: 'covid',
          title: 'Сводки'
        },
        {
          id: 'institution',
          title: 'Учреждения'
        }
      ],
      searchText: null,
      timeout: null,
      selectedView: 'table',
      list: [],
      isShowMap: true,
      mapZoom: 12,
      myMap: null,
      filterStartDate: null,
      selectedDate: '1970-01-01',
      countSelect: 0
    };
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },
    BalloonCard() {
      return BalloonCard;
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    filteredMap() {
      const _this = this;
      let list = {};

      if (!_this.$store.state?.all?.length) return [];
      let objects = this.$store.state.all;
      if (_this.searchText) {
        objects = objects.filter(
          el => (el.title ? (el.title.toLowerCase()
            .indexOf(_this.searchText.toLowerCase()) >= 0) : false)
        );
      }
      list = objects.map(el => ({
        ...el,
        titles: {
          text: el.title,
          address: el?.details?.length > 0 ? JSON.parse(el.details).address : ''
        }
      }));

      list = list.filter(el => (el?.lat));

      _this.countSelect++;

      return list;
    },
    filteredList() {
      const _this = this;

      if (!_this.$store.state?.all?.length) return [];
      let objects = this.$store.state.all;
      objects = objects.filter(el => (el.title ? (el.title != 'ИТОГО') : false));
      if (_this.searchText) {
        objects = objects.filter(
          el => (el.title ? (el.title.toLowerCase()
            .indexOf(_this.searchText.toLowerCase()) >= 0) : false)
        );
      }

      _this.list = objects.map(el => ({
        check: '',
        title: {
          title: el.title,
          address: el.details
        },
        val: {
          ...el
        }

      }));
      return _this.list;
    },
    countObjects() {
      return this.filteredList?.length || 0;
    },
    countOrg() {
      return this.objectOrg?.length || 0;
    },
    objectOrg() {
      const _this = this;

      if (!_this.$store.state?.allOrg?.length) return [];
      let objects = this.$store.state.allOrg;

      objects = objects.map(el => ({
        ...el,
        titles: {
          text: el.title,
          address: el?.details?.length > 0 ? JSON.parse(el.details).address : ''
        }
      }));
      if (_this.searchText) {
        objects = objects.filter(
          el => (el.title ? (el.title.toLowerCase()
            .indexOf(_this.searchText.toLowerCase()) >= 0) : false)
        );
      }

      return objects;
    }
  },
  async mounted() {
    const _this = this;
    if (_this.$route.query.q == 'institution') {
      _this.activeTabId.id = 'institution';
    }
    await _this.$store.dispatch('getAll', null).then(item => {
      this.$store.dispatch('getOrg');
    });
  },
  methods: {
    formatDate(date) {
      date = new Date(date);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      this.selectedDate = `${yy}-${mm}-${dd}`;
    },
    dateFunc: _.debounce(function () {
      const _this = this;
      _this.formatDate(this.filterStartDate);
      if (_this.selectedDate != '1970-01-01') {
        _this.$store.dispatch('getAll', this.selectedDate).then(() => {
          _this.countSelect++;
        });
      } else {
        _this.$store.dispatch('getAll', _this.formatDate(new Date())).then(() => {
          _this.countSelect++;
        });
      }
    }, 500),
    searchFunc() {
      this.count++;
    },
    markersWasAdd() {
      this.centeredMap();
    },
    initMap(e) {
      this.map = e;
    },
    centeredMap() {
      if (this.map && this.filteredMap.length > 0) {
        const pointsList = [];
        if (this.filteredMap.length > 0) {
          this.filteredMap.forEach(item => {
            pointsList.push([item.lat, item.lng]);
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 100,
              duration: 300
            });
          }
        });
      }
    },
    clickTab() {
      //  this.selectedView = 'table';
    },
    onZoom(val) {
      const v = this;
      const newZoom = v.mapZoom + val;
      v.mapZoom = newZoom;
      v.myMap.setZoom(v.mapZoom);
    },
    searchAddress(e) {
      this.searchText = e;
    },
    onMapClick() {
      this.selectedMarkerId = null;
    },
    mapClick(item) {
      this.selectedView = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.zoom {
  position: absolute;
  z-index: 10;
  right: 16px;
  margin-top: 16px;
}

.rir-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);

    > * {
      background-color: #fff;
    }
  }

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__legend {
    padding: 8px 12px;
    background: var(--rir-amelie);
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 4px;
  }
}

.objects {
  background: #f6f9fe;
  margin-left: -32px;
  margin-right: -40px;
  margin-top: -32px;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 40px;
  border-top-left-radius: 48px;
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.rir-popover {
  display: flex;
  align-items: center;

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}
::v-deep .rir-popover {
  display: table !important;
}
::v-deep .fill-rocky path{
  fill: #3D75E4!important;
}
.fill-secondary {
  fill: #ffffff!important;
}
::v-deep .ymaps-2-1-79-gotoymaps__container {
  display: none;
}
::v-deep .ymaps-2-1-79-gototech {
  display: none;
}
</style>
