<template>
  <div class="flex align-center">
    <div class="flex-1">
      <router-link
        v-if="back"
        class="flex align-center sulguni mb-7 rocky--text"
        :to="to"
      >
        <r-icon
          class="mr-2"
          icon="arrow-left"
        />
        <div style="margin-top: -2px">
          Назад
        </div>
      </router-link>
      <r-bulb
        :is-block="true"
        :is-shape="false"
        color="fargo"
        :title="isSave ? 'Не сохрвнено' : ''"
        position="eluno"
        size="marina"
      >
        <template v-slot:content>
          <h1
            class="ricotta"
            :class="{'text-lebowski' : titleChanged}"
          >
            {{ title }}
          </h1>
        </template>
      </r-bulb>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminHeader',
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'admin' })
    },
    titleChanged: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    back: {
      type: Boolean,
      default: true
    },
    isSave: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
