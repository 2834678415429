<template>
  <div>
    <div
      class="table"
    >
      <scroll-bar
        v-if="filteredList.length > 0"
        :options="{ alwaysShowTracks: true }"
      >
        <div
          class="table_head"
          ref="tableHead"
        >
          <div
            class="table_row mozzarella"
            :style="
              '' +
                !!columnsGrid
                ? 'grid-template-columns:' + columnsGrid.join(' ')
                : 'grid-auto-flow:column;'
            "
          >
            <div
              class="table_col pointer flex align-center"
              v-for="(head, index) of listC"
              @click="sortingClick(head.field)"
            >
              <div
                style="position: relative;bottom: 3px;"
                v-if="head.field == 'check'"
              >
                <r-checkbox
                  label=""
                  :value="$isActive"
                  v-model="$isActive"
                  @input="clickCheck()"
                />
              </div>
              <div
                v-else
                style="width: 90%;"
              >
                <div style="position: relative;bottom: 3px;font-size: 12px !important;line-height: 16px !important;">
                  {{ head.title }}
                </div>
                <div
                  class="text-st"
                  style="position: absolute; bottom: 12px;"
                  v-if="head.field == 'title'"
                >
                  Всего {{ total['end'] || 0 }}
                </div>
                <div
                  class="text-st text-m"
                  style="position: absolute; bottom: 12px;"
                  v-else
                >
                  {{ total[head.field] || 0 }}
                </div>
              </div>
              <svg
                style="margin-bottom: 4px;"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.37829 11.835C8.16934 12.055 7.83058 12.055 7.62163 11.835L5.15665 9.96155C4.94771 9.74158 4.94771 9.38494 5.15665 9.16498C5.3656 8.94501 5.70436 8.94501 5.91331 9.16498L7.99996 10.6402L10.0866 9.16497C10.2956 8.94501 10.6343 8.94501 10.8433 9.16497C11.0522 9.38494 11.0522 9.74158 10.8433 9.96154L8.37829 11.835Z"
                  :fill="currentSortDir == 'desc' && currentSort == head.field ? '#3D75E4': '#C0D6F6'"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.6217 4.16497C7.83064 3.94501 8.16939 3.94501 8.37832 4.16497L10.8433 6.03846C11.0522 6.25842 11.0522 6.61506 10.8433 6.83503C10.6344 7.05499 10.2956 7.05499 10.0867 6.83503L8.00001 5.35983L5.91332 6.83503C5.70439 7.05499 5.36564 7.05499 5.1567 6.83503C4.94777 6.61506 4.94777 6.25842 5.1567 6.03846L7.6217 4.16497Z"
                  :fill="currentSortDir == 'asc' && currentSort == head.field ? '#3D75E4': '#C0D6F6'"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="table_body"
          v-if="filteredList.length"
        >
          <div
            class="table_row"
            v-for="(row, index) of filteredList"
            :style="
              !!columnsGrid
                ? 'grid-template-columns:' + columnsGrid.join(' ')
                : 'grid-auto-flow:column;'
            "
          >
            <div
              v-for="(col, colIndex) of row"
              v-if="colIndex != 'val'"
              class="mr-auto"
            >
              <div
                class="pointer"
                style="display: table-cell;vertical-align: middle;"
              >
                <div
                  class="table_col flex align-center feta"
                  v-if="colIndex == 'title'"
                >
                  <div>
                    <div>
                      {{ col.title }}
                    </div>
                    <div class="types mt-1">
                      {{ col?.address ? JSON.parse(col.address).address : '-' }}
                    </div>
                  </div>
                </div>
                <div
                  class="table_col flex align-center feta"
                  @click.stop="joinCourse(row.val.id)"
                  v-if="colIndex == 'check'"
                >
                  <div

                    :key="count"
                  >
                    <r-checkbox
                      label=""
                      :value="$active[row.val.id]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(val, valIndex) of row['val']"
              v-if="countVal(val, valIndex)"
              class="mr-auto"
            >
              <div
                class=""
                style="display: table-cell;vertical-align: middle;"
              >
                <div>
                  <div>
                    {{ val || 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footerLine" />
        </div>
      </scroll-bar>
      <div
        class="button_container flex align-center"
        v-if="$isButton"
      >
        <div
          class="flex custom pointer fargo-hover pointer mr-auto align-items-center"
          @click="clickInfo()"
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 3C0 1.34315 1.34315 0 3 0H9.17157C9.96722 0 10.7303 0.31607 11.2929 0.87868L13.1213 2.70711C13.6839 3.26972 14 4.03278 14 4.82843V13C14 14.6569 12.6569 16 11 16H10.5C9.94771 16 9.5 15.5523 9.5 15C9.5 14.4477 9.94771 14 10.5 14H11C11.5523 14 12 13.5523 12 13V4.82843C12 4.56321 11.8946 4.30886 11.7071 4.12132L9.87868 2.29289C9.69114 2.10536 9.43679 2 9.17157 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H3.5C4.05228 14 4.5 14.4477 4.5 15C4.5 15.5523 4.05228 16 3.5 16H3C1.34315 16 0 14.6569 0 13V3Z"
              fill="#4480F3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.9999 9.49995C7.55219 9.49995 7.9999 9.94767 7.9999 10.5V12.1336C8.38233 11.9126 8.87983 11.9657 9.20701 12.2928C9.59753 12.6834 9.59753 13.3165 9.20701 13.7071L7.70701 15.2071C7.31648 15.5976 6.68332 15.5976 6.2928 15.2071L4.7928 13.7071C4.40227 13.3165 4.40227 12.6834 4.7928 12.2928C5.11998 11.9657 5.61747 11.9126 5.9999 12.1336V10.5C5.9999 9.94767 6.44762 9.49995 6.9999 9.49995Z"
              fill="#4480F3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.4998 4.5C3.4998 3.94772 3.94751 3.5 4.4998 3.5H6.9998C7.55208 3.5 7.9998 3.94772 7.9998 4.5C7.9998 5.05228 7.55208 5.5 6.9998 5.5H4.4998C3.94751 5.5 3.4998 5.05228 3.4998 4.5Z"
              fill="#4480F3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.5 7.49998C3.5 6.94769 3.94771 6.49998 4.5 6.49998H9.5C10.0523 6.49998 10.5 6.94769 10.5 7.49998C10.5 8.05226 10.0523 8.49998 9.5 8.49998H4.5C3.94771 8.49998 3.5 8.05226 3.5 7.49998Z"
              fill="#4480F3"
            />
          </svg>
          <div
            class="text-rocky ml-2 pointer"
          >
            Выгрузить отчет
          </div>
        </div>
        <div
          class="flex custom pointer fargo-hover pointer ml-auto align-items-center"
          @click="onDelete()"
        >
          <r-icon
            icon="delete"
            fill="fargo"
          />
          <div
            class="text-fargo ml-2 pointer"
          >
            Удалить
          </div>
        </div>
      </div>
      <r-modal
        ref="modal"
        close-icon
        fullscreen
      />
    </div>
    <model ref="childComponent" />
  </div>
</template>

<script>
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import Model from '@/components/Model.vue';
import Api from '../api/Api';

const api = new Api();

export default {
  name: 'Table',
  components: {
    ScrollBar,
    Model
  },
  props: {
    headings: {
      type: [Array, Object],
      default: () => []
    },
    col: {
      type: [Array, Object],
      default: () => []
    },
    listItem: {
      type: [Array, Object],
      default: () => []
    },
    total: {
      type: [Array, Object],
      default: () => []
    },
    columnsGrid: {
      type: Array
    },
    startSortedColumn: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: null
    },
    selectedDate: {
      type: Number,
      default: null
    },
    dateInfo: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      countSearch: 0,
      years: [],
      rows: [],
      currentSort: null,
      currentSortDir: 'asc',
      list: [],
      id: 0,
      count: 0,
      values: [],
      listCol: [],
      countDate: 0
    };
  },
  computed: {
    filterVal() {
      if (this.values.length == 0) {
        this.$store.state.col.columns.map(el => (
          this.values.push(el.name)
        ));
      }
      return this.values;
    },
    filteredList() {
      const _this = this;
      let list = [];
      if (_this.list.length == 0) {
        _this.list = _this.listItem;

        _this.$isActive = true;
        _this.$isButton = false;
        _this.list.map(value => {
          if (!_this.$active[value.val.id]) {
            _this.$active[value.val.id] = false;
            _this.$isActive = false;
          }
          if (_this.$active[value.val.id] == true) _this.$isButton = true;
        });
      }
      list = _this.list;
      if (_this.search) {
        list = list.filter(
          el => (el.title.title ? (el.title.title.toLowerCase()
            .indexOf(_this.search.toLowerCase()) >= 0) : false)
        );
      }

      this.countDate++;

      return list;
    },
    listC() {
      return this.headings;
    }
  },
  updated() {
  },
  mounted() {
  },
  methods: {
    onDelete() {
      const organizationIds = [];
      this.list.map(value => {
        if (this.$active[value.val.id] == true) {
          organizationIds.push(Number(value.val.organizationId));
        }
      });
      const item = { delete: true, dateInfo: this.dateInfo, organizationIds };
      this.$emit('updateDelete', item);
    },
    clickInfo() {
      const ids = [];
      this.list.map(value => {
        if (this.$active[value.val.id] == true) {
          ids.push(Number(value.val.id));
        }
      });
      const api = new Api();
      if (ids.length > 0) {
        api.getPrint(ids, this.dateInfo).then(item => {
          if (item?.url) {
            const link = document.createElement('a');
            link.setAttribute('href', item.url);
            link.setAttribute('download', item.url.split('/')[item.url.split('/').length - 1]);
            link.click();
          }
        });
      }
    },
    countVal(val, valIndex) {
      return this.filterVal.filter(item => item == valIndex).length > 0;
    },
    clickCheck() {
      const _this = this;
      _this.$isButton = false;
      _this.list.map(value => {
        _this.$active[value.val.id] = _this.$isActive;
        if (_this.$isActive == true) {
          _this.$isButton = true;
        }
      });
      _this.count++;
    },
    joinCourse(id) {
      const _this = this;
      _this.$isActive = true;
      _this.$isButton = false;
      _this.$active[id] = !_this.$active[id];
      _this.list.map(value => {
        if (_this.$active[value.val.id] == false) _this.$isActive = false;
        if (_this.$active[value.val.id] == true) _this.$isButton = true;
      });
      _this.count++;
    },
    link(id) {
      const r = this.$router.resolve({
        name: 'edit',
        params: { id }
      });

      window.location.assign(r.href);
    },
    sortingClick(field) {
      if (field == this.currentSort) {
        this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = field;
      if (this.currentSort) {
        this.list.sort((a, b) => {
          let modifier = 1;

          if (this.currentSortDir == 'desc') modifier = -1;
          if (this.currentSort == 'title') {
            if (a[this.currentSort].title < b[this.currentSort].title) return -1 * modifier;
            if (a[this.currentSort].title > b[this.currentSort].title) return 1 * modifier;
          } else {
            if (a.val[this.currentSort] < b.val[this.currentSort]) return -1 * modifier;
            if (a.val[this.currentSort] > b.val[this.currentSort]) return 1 * modifier;
          }
          return 0;
        });
      }
      this.count++;
    }
  }
};
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}

::v-deep .subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
  overflow: hidden;

  &_col {

    color: #04153e;

    .overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.btn {
  display: table;
  background: rgb(255, 255, 255);
  min-width: calc(100% - 25.3%);
  min-height: calc(100% - 96px);
  margin-left: -32px;
}

.rir-tooltip {
  position: relative;
}

.rir-tooltip__wrapper--arrow {
  position: absolute;
  top: 100%;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  background-color: transparent !important;
}

.rir-tooltip__wrapper {
  position: absolute;
  top: calc(100% + 6px);
  border-radius: 12px;
  padding: 4px 8px;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0 4px 16px rgba(48, 3, 14, 0.16));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
}

.table_row {
  display: grid;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #e4edfb;
  position: relative;
  width: max-content;
  min-width: 100%;

  &.hidden {
    overflow: hidden;
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f9fe;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
    transition-delay: 0.3s;
    cursor: pointer;
    opacity: 0;

    .rir-icon {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      .rir-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.table_body {

  .table_row {
    background: #ffffff;

    &:hover {
      background: #f6f9fe;
    }
    &:nth-child(2n) {
      background: #f6f9fe;
    }
    &.error {
      &:hover {
        background: #fef8f9;

        .rir-tooltip__wrapper {
          opacity: 1;
        }
      }

      .rir-checkbox {
        pointer-events: none;
      }

      .rir-checkbox__input:before {
        border: 2px solid #e14761;
        opacity: 0.32;
      }
    }
  }
}

.table_head {
  width: max-content;
  min-width: 100%;

  .table_row {
    border: none;
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.img {
  width: 76px;
}

.ghost {
  opacity: 0.3;
  //background: red;
}

::v-deep .scrollbar-track-x {
  background: #f6f9fe;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 90px;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}

.rir-tooltip {
  &__wrapper {
    background-color: var(--rir-amelie);
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    left: -10px;
    top: calc(100% + 10px);
    max-width: 345px;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;

    &--arrow {
      border-bottom-color: var(--rir-amelie);
      border-top-color: var(--rir-amelie);
      z-index: 100;
      top: -6px;
    }
  }
}

.pb-100px {
  padding-bottom: 100px;
}

::v-deep .c-scroll-view {
}

.table_row {
  &.disabled {
    .feta {
      opacity: .48;
    }
  }

  &.error {
    background: #FEF8F9;
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

.types {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}
.photo {
  margin-top: 7px;
  width:72px;
  height:72px;
  overflow:hidden;
  border-radius: 8px;
}
.photo_img {
  height: 72px;
  border-radius: 8px;
}
.footerLine {
  display: table;
  width: 17px;
  height: 110px;
  background: white;
}
.over {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-st {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  position: fixed;
  display: table;
}
.text-m {
  text-align: right;
}
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}
</style>
