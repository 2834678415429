<template>
  <div
    ref="content"
    class="button_fly"
  >
    <admin-header
      :title="'Новая сводка'"
      :is-save="!isSave"
    />
    <loader v-if="isLoading" />
    <template v-else>
      <div class="form mt-8 flex">
        <div
          class="flex flex-1"
          style="flex-direction: column;"
        >
          <r-select
            :key="count"
            label="Учреждение"
            class="mt-4"
            v-model="selectidId"
            @input="isChange"
            :items="selected"
          />
          <div class="flex mb-6 mt-6 mr-10">
            <r-date-picker
              click-close
              label="Дата"
              class="flex-1"
              v-model="inputDate"
              @input="isChangeDate"
            />
            <div
              click-close
              class="flex-1 text-rocky mt-3"
            />
          </div>
          <div
            class="flex mb-8"
            v-for="(val, valIndex) of $store.state.columns"
          >
            <div class="fon">
              {{ val.title }}
            </div>
            <div class="flex-1 flex m-buttom">
              <div @click="minus(val.name)">
                <r-icon
                  class="mr-2 max-icon pointer"
                  fill="rocky"
                  style="margin-top: 11px;"
                  icon="remove"
                />
              </div>
              <div :key="val.name + inputKey">
                <r-input
                  class="max"
                  label=""
                  :is-clear="false"
                  v-model="value[val.name]"
                />
              </div>
              <div @click="plus(val.name)">
                <r-icon
                  style="margin-top: 11px;"
                  class="ml-2 max-icon pointer"
                  fill="rocky"
                  icon="add"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="ml-6 flex flex-1"
          style="max-width: 460px;flex-direction: column;"
        >
          <rir-map
            collapse-btn
            style="height: 415px"
            class="mb-6"
          >
            <ymap-marker
              :coords="[lat, lng]"
              :marker-id="'marker'"
              :icon="$markerIconPoint()"
              :options="{
                hideIconOnBalloonOpen: false,
                draggable: false
              }"
            />
          </rir-map>

          <div
            class="mt-2"
            style="width: 100%;"
          >
            <r-input
              before-icon="search"
              label="Адрес"
              v-model="address"
              @blur="searchAddress(address)"
              @onPressEnter="searchAddress(address)"
            />
          </div>
        </div>
      </div>
      <div
        class="button_container flex align-center"
      >
        <r-button
          :disabled="isSave"
          @click="submit()"
          width="wide"
          title="Добавить"
        />
      </div>
    </template>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import AdminHeader from '../components/AdminHeader';
import RirMap from '../components/RirMap';
import Api from '../api/Api';

export default {
  name: 'NewInstitution',
  components: {
    Loader,
    AdminHeader,
    RirMap
  },
  data() {
    return {
      isSave: true,
      isLoading: false,
      lat: this.$cityCenter[0],
      lng: this.$cityCenter[1],
      address: null,
      coordinates: [],
      selectidId: 0,
      selected: [],
      count: 0,
      value: [],
      inputKey: 0,
      inputDate: null
    };
  },
  async mounted() {
    const _this = this;
    let id = 0;
    await _this.$store.dispatch('getAll', null).then(item => {
      _this.$store.state.columns.forEach(el => {
        _this.value[el.name] = 0;
      });
      _this.$store.dispatch('getOrg').then(value => {
        _this.selected = _this.$store.state.allOrg.map(el => (
          {
            title: el.title,
            id: id++
          }));
        _this.count++;
      });
    });
  },
  methods: {
    formatDate(date) {
      date = new Date(date);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${yy}-${mm}-${dd}`;
    },
    minus(name) {
      if (this.value[name] > 0) {
        this.value[name]--;
        this.inputKey++;
      }
    },
    plus(name) {
      this.value[name]++;
      this.inputKey++;
    },
    isChange() {
      const _this = this;
      let title = null;
      const api = new Api();
      _this.selected.map(el => {
        if (el.id == _this.selectidId) {
          title = el.title;
        }
      });
      api.getOrgTitle(title).then(res => {
        if (res?.all?.length > 0) {
          const json = res.all[0];
          if (_this.lat) {
            _this.lat = json.lat;
            _this.lng = json.lng;
            _this.address = JSON.parse(json.details)?.address ? JSON.parse(json.details).address : '';
          }
        }
      });
    },
    isChangeDate() {
      if (this.inputDate == null) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.searchAddress(coords);
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new Api();
      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        this.lat = address[0];
        this.lng = address[1];
      }
      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.lat = res.lat;
        this.lng = res.lng;
      }
    },
    onDragMarker(e) {
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.coordinates = coords;
        this.searchAddress(this.coordinates);
      }
    },
    submit() {
      const api = new Api();

      const _this = this;
      let vals = '';
      let title = null;
      const json = {};

      json.selectedDate = _this.formatDate(_this.inputDate);
      _this.selected.map(el => {
        if (el.id == _this.selectidId) {
          title = `${el.title};`;
        }
      });

      Object.values(_this.value).forEach(entry => {
        vals += `${entry};`;
      });

      json.data = String(title + vals);

      api.setSave(json).then(result => {
        const r = this.$router.resolve({
          name: 'index',
          params: {}
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-textarea__label {
  color: rgba(4, 21, 62, 0.32);
}

.grid {
  grid-template-columns: 150px auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  align-items: center;
}

::v-deep .rir-textarea__textarea:focus {
  background: transparent !important;
}
::v-deep .rir-popover__activator .rir-select.disabled{
  opacity: .72!important;
  pointer-events: none!important;
}
::v-deep .rir-input.readonly:not(.rir-select){
  opacity: .72!important;
}
.fon {
  padding-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 270px;
}
.max {
  width: 72px;
}
.max-icon {
  display: table;
  width: 22px;
  margin-top: 8px;
}
</style>
<style>
.max .RInput__body .RInput__input input {
  text-align: center;
}
.m-buttom .rir-input__input input {
  text-align: center;
}
.rir-date-picker__time {
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  background: #fff;
  border-radius: 16px;
  padding: 20px 0;
  box-shadow: 0 8px 24px rgba(19, 57, 134, .16);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 112px;
  box-sizing: border-box;
}

.rir-date-picker__time p {
  margin: 0 0 16px 0;
  color: #04153e;
  opacity: .48
}

.rir-date-picker__time .hour {
  margin-left: 16px
}

.rir-date-picker__time .hour, .rir-date-picker__time .minutes {
  padding-right: 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  scrollbar-color: rgba(4, 21, 62, .08) transparent
}

.rir-date-picker__time .hour::-webkit-scrollbar-track, .rir-date-picker__time .minutes::-webkit-scrollbar-track {
  width: 4px;
  background: #fff
}

.rir-date-picker__time .hour::-webkit-scrollbar, .rir-date-picker__time .hour::-webkit-scrollbar-thumb, .rir-date-picker__time .minutes::-webkit-scrollbar, .rir-date-picker__time .minutes::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72
}

.rir-date-picker__time .hour > span, .rir-date-picker__time .minutes > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  color: #113079;
  margin-bottom: 8px;
  border-radius: 4px;
  flex-shrink: 0
}

.rir-date-picker__time .hour > span.active, .rir-date-picker__time .minutes > span.active {
  color: #fff;
  background: #3d75e4
}

.rir-date-picker__time .hour > span:not(.active):hover, .rir-date-picker__time .minutes > span:not(.active):hover {
  background-color: #e4edfb;
  color: #3d75e4
}

.rir-date-picker__time_wrapper {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 20px)
}
.rir-date-picker__time>div {
  display: flex;
  flex-direction: initial !important;
  width: 100% !important;
  overflow: auto;
}
.rir-date-picker__content {
  overflow: visible !important;
}
.m-buttom {
  margin-left: 50px;
  top: calc(50% - 14px);
  position: relative;
}
</style>
<style>
.fargo {
  background-color: unset;
}
</style>
