import Vue from 'vue';
import Rir from '@rir/vue-library';
// import './vue-library/RirLib.css';
Vue.use(Rir);

const options = {};
export default new Rir(options);

/*
import library from './ui/RirUI.umd.min';

export default {
  async install(Vue) {
    const { Components, Directives, Mixins } = library;
    Object.keys(Components).forEach(name => {
      Vue.component(Components[name].name, Components[name]);
    });
    Object.entries(Directives).forEach(directive => {
      Vue.use(directive);
    });
    Object.values(Mixins).forEach(mixin => {
      Vue.mixin(mixin);
    });
  }
};
*/
