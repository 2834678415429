<template>
  <scroll-table
    :key="selectedDate"
    :headings="$store.state.contracts.headings"
    :list-item="items"
    row-link="/contract/"
    :columns-grid="listCol"
    :total="total"
    :search="search"
    :date-info="dateInfo"
    :selected-date="selectedDate"
    start-sorted-column="title"
  />
</template>

<script>
import ScrollTable from './Table';

export default {
  name: 'TableCovid',
  components: {
    ScrollTable
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: null
    },
    selectedDate: {
      type: Number,
      default: null
    },
    dateInfo: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      total: [],
      list: [],
      tableGridColumns: [
        '40px',
        '352px'
      ]
    };
  },
  computed: {
    listCol() {
      const _this = this;
      this.tableGridColumns = [
        '40px',
        '352px'
      ];
      _this.total.end = 0;
      const listCol = [];
      listCol.push(_this.$store.state.contracts.headings[0]);
      listCol.push(_this.$store.state.contracts.headings[1]);
      _this.$store.state.col?.columns.map(el => (
        listCol.push({
          title: el.title,
          field: el.name
        })
      ));
      _this.$store.state.contracts.headings = listCol;

      _this.$store.state.col?.columns.map(el => {
        _this.tableGridColumns.push('200px');

        _this.total[el.name] = 0;

        _this.items.map(item => {
          _this.total.end += Number(item.val[el.name]);
          _this.total[el.name] += Number(item.val[el.name]);
        });
      });

      return this.tableGridColumns;
    }
  }
};
</script>

<style scoped></style>
