<template>
  <div
    class="obj-card"
  >
    <div
      @click="tasks()"
      class="flex-1 clickLink"
    >
      <div
        class="flex-1 clickLink"
      >
        <div class="obj-card__info overHidden">
          <div
            :class="['obj-card__title sulguni overHidden mr-2']"
            style="float: left;"
          >
            {{ serviceTitle }}
          </div>
        </div>

        <div class="obj-card__counters flex align-center">
          <div
            class="flex"
            style="
          position: absolute;
          bottom: 16px;
          width: 95%;"
          />
        </div>
      </div>
    </div>
    <div class="flex">
      <additional-menu-project>
        <ul
          class="obj-card__menu"
          style="padding-left: 0em;"
        >
          <li
            class="sulguni flex pointer align-items-center"
            style="color: #e14761"
            @click.stop="deleteOrg"
          >
            <r-icon
              class="mr-2"
              icon="delete"
              fill="fargo"
            />
            <div>Удалить</div>
          </li>
        </ul>
      </additional-menu-project>
    </div>
    <model ref="childComponent" />
  </div>
</template>
<script>
import Model from '@/components/Model.vue';
import AdditionalMenuProject from './AdditionalMenuProject';

export default {
  components: {
    AdditionalMenuProject,
    Model
  },
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'admin' })
    },
    id: {
      type: String,
      default: ''
    },
    serviceTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    tasks() {
      const r = this.$router.resolve({
        name: 'edit-institution',
        params: { id: this.id }
      });
      window.location.assign(r.href);
    },
    deleteOrg() {
      const item = { id: this.id };
      this.$emit('updateDelete', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip .tooltiptext {
  margin-top: -40px;
  margin-left: -5px;
  visibility: hidden;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltipicon {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: #FFFFFF transparent  transparent transparent;
  top: 30px;
  left: 10px;
}
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 12px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: table;
  }

  &__menu {
    cursor: pointer;
  }

  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 14px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.opacity-32 {
  opacity: 0.32;
}

.style-marin-house {
  margin-top: 6px;
}

.float-left {
  float: left;
}
.overHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

}
.color_footer {
  color: #A2ABBE;
}
.rir-map-balloon {
  &__img {
    width: 72px;
    height: 72px;
    aspect-ratio: 16/10;
    object-fit: cover;
    border-radius: 8px;
  }
}
.pointer {
  cursor: pointer;
}
.photo {
  margin-top: 7px;
  width:72px;
  height:72px;
  overflow:hidden;
  border-radius: 8px;
}
.photo_img {
  height: 72px;
  border-radius: 8px;
}
.clickLink {
  width: calc(100% - 114px);
  cursor: pointer;
}
.line {
  text-decoration: line-through;
}
.pin {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.overHiddenToo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
