<template>
  <div>
    <template>
      <table-covid
        :key="search"
        :search="search"
        :items="items"
        :selected-date="selectedDate"
        :date-info="dateInfo"
      />
    </template>
  </div>
</template>

<script>
import TableCovid from './TableCovid';

export default {
  name: 'TabList',
  components: {
    TableCovid
  },
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    },
    search: {
      type: String,
      default: null
    },
    selectedDate: {
      type: Number,
      default: null
    },
    dateInfo: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
::v-deep .ymaps-2-1-79-balloon__layout,
::v-deep .ymaps-2-1-79-balloon__content{
  background: transparent!important;
}
::v-deep .ymaps-2-1-79-balloon{
  box-shadow: none!important;
}
</style>
