import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import contracts from './modules/contracts';

const api = new Api();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    all: null,
    allOrg: null,
    isObjectLoading: true,
    col: null,
    columns: null
  },
  mutations: {
    setColumns(state, res) {
      state.columns = res;
    },
    setAll(state, res) {
      state.all = res;
    },
    setCol(state, res) {
      state.col = res;
    },
    setOrg(state, res) {
      state.allOrg = res;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    }
  },
  getters: {
  },
  actions: {
    async getAll(ctx, date) {
      const res = await api.getAll(date);
      ctx.commit('setObjectLoading', true);
      ctx.commit('setAll', res.all);
      ctx.commit('setColumns', res.columns);
      ctx.commit('setCol', res);

      ctx.commit('setObjectLoading', false);
      return res;
    },
    async getOrg(ctx) {
      const res = await api.getOrg();
      ctx.commit('setObjectLoading', true);
      ctx.commit('setOrg', res.all);

      ctx.commit('setObjectLoading', false);
      return res;
    }
  },
  modules: {
    contracts
  }
});
