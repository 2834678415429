import ApiService from './ApiService';

export default class Api extends ApiService {
  async getDelete(value) {
    const JSON = {
      action: 'delete',
      id: Number(value)
    };
    const { data } = await this._axios.post('/ajax.php?action=operateCOVIDorganization', JSON);

    return data;
  }

  async getDeleteIds(value, date) {
    const JSON = {
      action: 'deleteArchive',
      organizationIds: value,
      selectedDate: date
    };
    const { data } = await this._axios.post('/ajax.php?action=operateCOVID', JSON);

    return data;
  }

  async getAll(date) {
    const JSON = {
      archiveViewMode: 1,
      selectedDate: date
    };
    const { data } = await this._axios.post('/ajax.php?action=getCOVID',
      JSON);

    return data;
  }

  async getOrg(JSON) {
    const { data } = await this._axios.post('/ajax.php?action=getCOVIDorganizations',
      JSON);

    return data;
  }

  async getOrgUpdate(item, id) {
    const JSON = {
      id,
      action: 'update',
      item
    };

    const { data } = await this._axios.post('/ajax.php?action=operateCOVIDorganization', JSON);

    return data;
  }

  async getOrgId(id) {
    const JSON = {
      id
    };

    const { data } = await this._axios.post('/ajax.php?action=getCOVID', JSON);

    return data;
  }

  async getOrgTitle(title) {
    const JSON = {
      title
    };

    const { data } = await this._axios.post('/ajax.php?action=getCOVID', JSON);

    return data;
  }

  async getPrint(ids, date) {
    const JSON = {
      selectedDate: date,
      ids,
      print: 1
    };

    const { data } = await this._axios.post('/ajax.php?action=getCOVID', JSON);

    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async setSave(JSON) {
    const { data } = await this._axios.post('/ajax.php?action=parseCovid', JSON);

    return data;
  }
}
