export default {
  state: () => ({
    headings: [
      {
        title: '',
        field: 'check'
      },
      {
        title: 'Учреждения',
        field: 'title'
      }
    ]
  })
};
