import Vue from 'vue';
import VueRouter from 'vue-router';
import EditInstitution from '@/views/EditInstitution.vue';
import New from '@/views/New.vue';
import Edit from '@/views/Edit.vue';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import NotFoundPage from '../views/NotFoundPage';
import NewInstitution from '../views/NewInstitution.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,

    children: [
      {
        name: 'index',
        path: '',
        component: ObjectList
      },
      {
        path: 'new',
        component: NewInstitution
      },
      /*      {
        path: 'edit/:id',
        name: 'edit',
        component: EditInstitution
      }, */
      {
        path: 'new-institution',
        component: New
      },
      {
        path: 'edit-institution/:id',
        name: 'edit-institution',
        component: Edit
      },
      { path: '*', name: 'NotFoundPage', component: NotFoundPage }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
