<template>
  <div
    ref="content"
    class="button_fly"
  >
    <admin-header :title="'МБУ «Городское лесничество»'">
      <sup
        class="text-c13 text-fargo"
        v-if="!isSave"
        :to="'index'"
      >Не сохранено</sup>
    </admin-header>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="form mt-8 flex">
        <div
          class="flex flex-1"
          style="flex-direction: column;"
        >
          <r-input
            label="Учреждение"
          />
          <div class="flex mb-6 mt-6">
            <r-date-picker
              click-close
              label="Дата"
              class="flex-1 mr-4"
            />
            <div
              click-close
              class="flex-1 text-rocky mt-3"
            />
          </div>
          <div class="flex mb-8">
            <div class="fon">
              Всего болело
            </div>
            <div class="flex-1 flex">
              <r-icon
                class="mr-2 max-icon pointer"
                fill="rocky"
                icon="remove"
              />
              <r-input
                class="max"
                label=""
                value="10"
              />
              <r-icon
                class="ml-2 max-icon pointer"
                fill="rocky"
                icon="add"
              />
            </div>
          </div>
          <div class="flex mb-8">
            <div class="fon">
              Сейчас болеют
            </div>
            <div class="flex-1 flex">
              <r-icon
                class="mr-2 max-icon pointer"
                fill="rocky"
                icon="remove"
              />
              <r-input
                class="max"
                label=""
                value="10"
              />
              <r-icon
                class="ml-2 max-icon pointer"
                fill="rocky"
                icon="add"
              />
            </div>
          </div>
          <div class="flex mb-8">
            <div class="fon">
              Выздоровели
            </div>
            <div class="flex-1 flex">
              <r-icon
                class="mr-2 max-icon pointer"
                fill="rocky"
                icon="remove"
              />
              <r-input
                class="max"
                label=""
                value="10"
              />
              <r-icon
                class="ml-2 max-icon pointer"
                fill="rocky"
                icon="add"
              />
            </div>
          </div>
          <div class="flex mb-8">
            <div class="fon">
              COVID
            </div>
            <div class="flex-1 flex">
              <r-icon
                class="mr-2 max-icon pointer"
                fill="rocky"
                icon="remove"
              />
              <r-input
                class="max"
                label=""
                value="10"
              />
              <r-icon
                class="ml-2 max-icon pointer"
                fill="rocky"
                icon="add"
              />
            </div>
          </div>
          <div class="flex align-center custom mt-2 mb-60px pointer fargo-hover pointer align-items-center">
            <r-icon
              icon="delete"
              fill="fargo"
            />
            <div class="text-fargo ml-2 pointer">
              Удалить сводку
            </div>
          </div>
        </div>
        <div
          class="ml-6 flex flex-1"
          style="max-width: 460px;flex-direction: column;"
        >
          <rir-map
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-6"
          >
            <ymap-marker
              :coords="[lat, lng]"
              :marker-id="'marker'"
              :icon="$markerIconPoint()"
              :options="{
                hideIconOnBalloonOpen: false,
                draggable: true
              }"
              @dragend="onDragMarker"
            />
          </rir-map>

          <div
            class="mt-2"
            style="width: 100%;"
          >
            <r-input
              label="Адрес"
              v-model="address"
              @blur="searchAddress(address)"
              @onPressEnter="searchAddress(address)"
              :params-input="{&quot;type&quot;:&quot;input&quot;}"
            >
              <template #after>
                <r-icon
                  icon="search"
                  fill="metropolis"
                  class="icon-margin"
                />
              </template>
            </r-input>
          </div>
        </div>
      </div>
      <div
        class="button_container flex align-center"
      >
        <r-button
          :disabled="false"
          @click="submit(true)"
          width="wide"
          title="Сохранить"
        />
      </div>
    </template>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import AdminHeader from '../components/AdminHeader';
import RirMap from '../components/RirMap';
import Api from '../api/Api';

const api = new Api();

export default {
  name: 'EditInstitution',
  components: {
    Loader,
    AdminHeader,
    RirMap
  },
  data() {
    return {
      isSave: true,
      isLoading: false,
      lat: this.$cityCenter[0],
      lng: this.$cityCenter[1],
      address: null,
      coordinates: []
    };
  },
  methods: {
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.searchAddress(coords);
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new Api();
      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        this.lat = address[0];
        this.lng = address[1];
      }
      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.lat = res.lat;
        this.lng = res.lng;
      }
    },
    onDragMarker(e) {
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.coordinates = coords;
        this.searchAddress(this.coordinates);
      }
    },
    submit(value) {
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-textarea__label {
  color: rgba(4, 21, 62, 0.32);
}

.grid {
  grid-template-columns: 150px auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  align-items: center;
}

::v-deep .rir-textarea__textarea:focus {
  background: transparent !important;
}
::v-deep .rir-popover__activator .rir-select.disabled{
  opacity: .72!important;
  pointer-events: none!important;
}
::v-deep .rir-input.readonly:not(.rir-select){
  opacity: .72!important;
}
.fon {
  padding-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 270px;
}
.max {
  display: table;
  width: 52px;
}
.max-icon {
  display: table;
  width: 22px;
  margin-top: 8px;
}
</style>
<style>
.rir-date-picker__time {
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  background: #fff;
  border-radius: 16px;
  padding: 20px 0;
  box-shadow: 0 8px 24px rgba(19, 57, 134, .16);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 112px;
  box-sizing: border-box;
}

.rir-date-picker__time p {
  margin: 0 0 16px 0;
  color: #04153e;
  opacity: .48
}

.rir-date-picker__time .hour {
  margin-left: 16px
}

.rir-date-picker__time .hour, .rir-date-picker__time .minutes {
  padding-right: 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  scrollbar-color: rgba(4, 21, 62, .08) transparent
}

.rir-date-picker__time .hour::-webkit-scrollbar-track, .rir-date-picker__time .minutes::-webkit-scrollbar-track {
  width: 4px;
  background: #fff
}

.rir-date-picker__time .hour::-webkit-scrollbar, .rir-date-picker__time .hour::-webkit-scrollbar-thumb, .rir-date-picker__time .minutes::-webkit-scrollbar, .rir-date-picker__time .minutes::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72
}

.rir-date-picker__time .hour > span, .rir-date-picker__time .minutes > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  color: #113079;
  margin-bottom: 8px;
  border-radius: 4px;
  flex-shrink: 0
}

.rir-date-picker__time .hour > span.active, .rir-date-picker__time .minutes > span.active {
  color: #fff;
  background: #3d75e4
}

.rir-date-picker__time .hour > span:not(.active):hover, .rir-date-picker__time .minutes > span:not(.active):hover {
  background-color: #e4edfb;
  color: #3d75e4
}

.rir-date-picker__time_wrapper {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 20px)
}
.rir-date-picker__time>div {
  display: flex;
  flex-direction: initial !important;
  width: 100% !important;
  overflow: auto;
}
.rir-date-picker__content {
  overflow: visible !important;
}
</style>
<style>
.fargo {
  background-color: unset;
}
</style>
