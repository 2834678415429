<template>
  <div>
    <loader v-if="isLoading" />
    <div
      class="objects__list mt-6"
      v-else
    >
      <card
        v-for="(item,index) of listItem"
        :id="item.id"
        ref="Card"
        :service-title="item.title"
        class="color-card"
      />
    </div>
  </div>
</template>

<script>
import Card from '@/components/ObjectCard.vue';
import infiniteLoading from 'vue-infinite-loading';
import Api from '@/api/Api';
import Loader from '@/components/Loader.vue';

export default {
  name: 'LoadCard',
  components: {
    Loader,
    Card,
    infiniteLoading
  },
  props: {
    listItem: {
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    isLoading() {
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.text__date {
  color: #0e1420;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }
}

.maxWidith {
  max-width: 100px;
}

.overHidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.color-card {
  color: #0e1420;
}
</style>
