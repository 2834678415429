!(function (t, e) {
  typeof exports === 'object' && typeof module !== 'undefined' ? e(exports, require('vue')) : typeof define === 'function' && define.amd ? define(['exports', 'vue'], e) : e((t = t || self)['vue-yandex-maps'] = {}, t.vue);
}(this, (t, o) => {
  function i(t) {
    return (i = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol' ? function (t) {
      return typeof t;
    } : function (t) {
      return t && typeof Symbol === 'function' && t.constructor === Symbol && t !== Symbol.prototype ? 'symbol' : typeof t;
    })(t);
  }

  function n(t, e) {
    for (let o = 0; o < e.length; o++) {
      const n = e[o];
      n.enumerable = n.enumerable || !1, n.configurable = !0, 'value' in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
    }
  }

  function r(t) {
    return (function (t) {
      if (Array.isArray(t)) {
        for (var e = 0, o = new Array(t.length); e < t.length; e++) o[e] = t[e];
        return o;
      }
    }(t)) || (function (t) {
      if (Symbol.iterator in Object(t) || Object.prototype.toString.call(t) === '[object Arguments]') return Array.from(t);
    }(t)) || (function () {
      throw new TypeError('Invalid attempt to spread non-iterable instance');
    }());
  }

  function a(t, e) {
    const l = e.options;
    const u = e.callbacks;
    const p = e.map;
    const m = e.useObjectManager;
    const d = e.objectManagerClusterize;
    const f = e.useHtmlInLayout ? '\n    <div v-html="properties.balloonContentHeader"></div>\n    <div v-html="properties.balloonContentBody"></div>\n    <div v-html="properties.balloonContentFooter"></div>\n  ' : '\n    <div>{{ properties.balloonContentHeader }}</div>\n    <div>{{ properties.balloonContentBody }}</div>\n    <div>{{ properties.balloonContentFooter }}</div>\n  ';
    const h = {};
    const o = [];
    if (t.forEach(t => {
      t.clusterName ? h[t.clusterName] = h[t.clusterName] ? [].concat(r(h[t.clusterName]), [t]) : [t] : o.push(t);
    }), Object.keys(h).forEach(t => {
      const e = l[t] || {};
      const o = u[t] || {};
      const n = e.layout || f;
      e.clusterBalloonItemContentLayout = ymaps.templateLayoutFactory.createClass(n);
      const r = e.clusterBalloonLayout || e.clusterLayout;
      delete e.clusterBalloonLayout;
      const a = r ? ymaps.templateLayoutFactory.createClass(r) : e.clusterBalloonContentLayout || 'cluster#balloonTwoColumns';
      e.clusterBalloonContentLayout = a;
      const i = e.clusterIconContentLayout;
      if (e.clusterIconContentLayout = i && ymaps.templateLayoutFactory.createClass(i), m) {
        const s = new ymaps.ObjectManager({clusterize: d, ...e});
        Object.keys(o).forEach(t => {
          s.clusters.events.add(t, o[t]);
        }), s.add(h[t]), p.geoObjects.add(s);
      } else {
        const c = new ymaps.Clusterer(e);
        Object.keys(o).forEach(t => {
          c.events.add(t, o[t]);
        }), e.createCluster && (c.createCluster = e.createCluster), c.add(h[t]), p.geoObjects.add(c);
      }
    }), o.length) {
      const n = m ? new ymaps.ObjectManager({clusterize: !1}) : new ymaps.GeoObjectCollection();
      o.forEach(t => n.add(t)), p.geoObjects.add(n);
    }
  }

  function m(t) {
    return t.charAt(0).toUpperCase() + t.slice(1);
  }

  function s(t, e) {
    const a = [];
    return (function t(e, o) {
      if (e === o) return !0;
      if (e instanceof Date && o instanceof Date) return +e == +o;
      if (i(e) !== 'object' || i(o) !== 'object') return !1;
      if (function (t, e) {
        for (let o = a.length; o--;) if (!(a[o][0] !== t && a[o][0] !== e || a[o][1] !== e && a[o][1] !== t)) return !0;
        return !1;
      }(e, o)) return !0;
      a.push([e, o]);
      const n = Object.keys(e);
      let r = n.length;
      if (Object.keys(o).length !== r) return !1;
      for (; r--;) if (!t(e[n[r]], o[n[r]])) return !1;
      return !0;
    }(t, e));
  }

  const g = new (function () {
    function t() {
      !(function (t, e) {
        if (!(t instanceof e)) throw new TypeError('Cannot call a class as a function');
      }(this, t)), this.events = {}, this.ymapReady = !1, this.scriptIsNotAttached = !0;
    }

    return (function (t, e, o) {
      e && n(t.prototype, e), o && n(t, o);
    }(t, [{
      key: '$on', value(t, e) {
        const o = this;
        return this.events[t] || (this.events[t] = []), this.events[t].push(e), function () {
          o.events[t] = o.events[t].filter(t => e !== t);
        };
      }
    }, {
      key: '$emit', value(t, e) {
        const o = this.events[t];
        o && o.forEach(t => t(e));
      }
    }])), t;
  }())();
  const
    e = ['fullscreenControl', 'geolocationControl', 'routeEditor', 'rulerControl', 'searchControl', 'trafficControl', 'typeSelector', 'zoomControl', 'routeButtonControl', 'routePanelControl'];

  function c(t) {
    return t.filter(t => ![].concat(e, ['default']).includes(t)).length === 0;
  }

  function l() {
    const v = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    return new Promise((t, e) => {
      if (window.ymaps) return t();
      if (document.getElementById('vue-yandex-maps')) g.$on('scriptIsLoaded', t); else {
        const o = document.createElement('SCRIPT');
        const n = v.apiKey;
        const r = void 0 === n ? '' : n;
        const a = v.lang;
        const i = void 0 === a ? 'ru_RU' : a;
        const s = v.version;
        const c = void 0 === s ? '2.1' : s;
        const l = v.coordorder;
        const u = void 0 === l ? 'latlong' : l;
        const p = v.debug;
        const m = void 0 !== p && p;
        const d = v.enterprise;
        const f = void 0 !== d && d;
        const h = m ? 'debug' : 'release';
        const y = 'lang='.concat(i).concat(r && '&apikey='.concat(r), '&mode=').concat(h, '&coordorder=').concat(u);
        const b = 'https://'.concat(f ? 'enterprise.' : '', 'api-maps.yandex.ru/').concat(c, '/?').concat(y);
        o.setAttribute('src', b), o.setAttribute('async', ''), o.setAttribute('defer', ''), o.setAttribute('id', 'vue-yandex-maps'), document.head.appendChild(o), g.scriptIsNotAttached = !1, o.onload = function () {
          ymaps.ready(() => {
            g.ymapReady = !0, g.$emit('scriptIsLoaded'), t();
          });
        }, o.onerror = e;
      }
    });
  }

  let u;
  let p = 1;
  const d = g;
  const f = ['actionend', 'balloonclose', 'balloonopen', 'click', 'contextmenu', 'dblclick', 'destroy', 'hintclose', 'hintopen', 'optionschange', 'sizechange', 'typechange'];
  const h = {
    pluginOptions: {}, provide() {
      let e;
      let r;
      let t;
      const a = this;
      let o = [];
      let i = [];
      return this.balloonComponent != null && (t = (function (n) {
        let r = null;
        const a = 'vue-balloon-'.concat(p);
        return p += 1, function (t, e) {
          var o = ymaps.templateLayoutFactory.createClass('<div id="'.concat(a, '"><div>'), {
            build() {
              o.superclass.build.call(this), (r = new u({
                parent: t.$root, data() {
                  return {props: t.$props, listeners: t.$listeners};
                }, propsData: {marker: e, component: n}
              })).$mount('#'.concat(a));
            }, clear() {
              r.$destroy(), r = null, o.superclass.clear.call(this);
            }
          });
          return o;
        };
      }(this.balloonComponent))), {
        useObjectManager: this.useObjectManager,
        addMarker: this.addMarker,
        deleteMarker(t) {
          a.$options.static.myMap.geoObjects && (o.push(t), e && clearTimeout(e), e = setTimeout(() => {
            a.deleteMarkers(o), o = [];
          }, 0));
        },
        compareValues(t) {
          const e = t.newVal;
          const o = t.oldVal;
          const n = t.marker;
          s(e, o) || (i.push(n), r && clearTimeout(r), r = setTimeout(() => {
            a.setMarkers(i), i = [];
          }, 0));
        },
        makeComponentBalloonTemplate: t
      };
    }, data() {
      return {
        ymapId: 'yandexMap'.concat(Math.round(1e5 * Math.random())),
        style: this.ymapClass ? '' : 'width: 100%; height: 100%;',
        isReady: !1,
        debounce: null
      };
    }, static: {myMap: {}, markers: []}, props: {
      coords: {type: Array, required: !0},
      zoom: {
        validator(t) {
          return !Number.isNaN(t);
        }, default: 18
      },
      bounds: Array,
      clusterOptions: {
        type: Object, default() {
          return {};
        }
      },
      clusterCallbacks: {
        type: Object, default() {
          return {};
        }
      },
      behaviors: {
        type: Array, default() {
          return ['default'];
        }
      },
      controls: {
        type: Array, default() {
          return ['default'];
        }, validator(t) {
          return c(t);
        }
      },
      detailedControls: {
        type: Object, validator(t) {
          return c(Object.keys(t));
        }
      },
      scrollZoom: {type: Boolean, default: !0},
      mapType: {
        type: String, default: 'map', validator(t) {
          return ['map', 'satellite', 'hybrid'].includes(t);
        }
      },
      placemarks: {
        type: Array, default() {
          return [];
        }
      },
      useObjectManager: {type: Boolean, default: !1},
      objectManagerClusterize: {type: Boolean, default: !0},
      ymapClass: String,
      initWithoutMarkers: {type: Boolean, default: !0},
      debug: {type: Boolean, default: !1},
      settings: {
        type: Object, default() {
          return {};
        }
      },
      options: {
        type: Object, default() {
          return {};
        }
      },
      mapEvents: {
        type: Array, default() {
          return [];
        }
      },
      showAllMarkers: Boolean,
      disablePan: Boolean,
      balloonComponent: {
        type: [Object, Function], default() {
          return null;
        }
      },
      useHtmlInLayout: Boolean
    }, computed: {
      coordinates() {
        return this.coords.map(t => +t);
      }
    }, methods: {
      init() {
        const a = this;
        if (window.ymaps && ymaps.GeoObjectCollection && (this.initWithoutMarkers || this.$slots.default || this.placemarks.length)) {
          if (this.$emit('map-initialization-started'), this.$options.static.myMap = new ymaps.Map(this.ymapId, {
            center: this.coordinates,
            zoom: +this.zoom,
            bounds: this.bounds,
            behaviors: this.behaviors,
            controls: this.controls,
            type: 'yandex#'.concat(this.mapType)
          }, this.options), (this.mapEvents.length ? this.mapEvents : f).forEach(e => a.$options.static.myMap.events.add(e, t => a.$emit(e, t))), this.$options.static.myMap.events.add('boundschange', t => {
            const e = t.originalEvent;
            const o = e.newZoom;
            const n = e.newCenter;
            const r = e.newBounds;
            a.$emit('boundschange', t), a.$emit('update:zoom', o), a.$emit('update:coords', n), a.$emit('update:bounds', r);
          }), this.detailedControls) Object.keys(this.detailedControls).forEach(t => {
            a.$options.static.myMap.controls.remove(t), a.$options.static.myMap.controls.add(t, a.detailedControls[t]);
          });
          !1 === this.scrollZoom && this.$options.static.myMap.behaviors.disable('scrollZoom'), this.isReady = !0, this.$emit('map-was-initialized', this.$options.static.myMap);
        }
      }, addMarker(t) {
        const e = this;
        this.$options.static.markers.push(t), this.debounce && clearTimeout(this.debounce), this.debounce = setTimeout(() => {
          e.setMarkers(e.$options.static.markers);
        }, 0);
      }, setMarkers(t) {
        const e = this;
        const o = {
          options: this.clusterOptions,
          callbacks: this.clusterCallbacks,
          map: this.$options.static.myMap,
          useObjectManager: this.useObjectManager,
          objectManagerClusterize: this.objectManagerClusterize,
          useHtmlInLayout: this.useHtmlInLayout
        };
        if (this.$options.static.markers !== t) {
          const n = t.map(t => (e.useObjectManager ? t.id : t.properties.get('markerId')));
          this.deleteMarkers(n), a(t, o), this.$emit('markers-was-change', n), console.log('markers-was-change', n);
        } else a(t, o), this.$emit('markers-was-add', t), console.log('markers-was-add', t);
        this.$options.static.markers = [], this.showAllMarkers && this.$options.static.myMap.setBounds(this.$options.static.myMap.geoObjects.getBounds());
      }, deleteMarkers(a) {
        const i = this;
        const e = [];
        this.$options.static.myMap.geoObjects.each(t => e.push(t)), e.forEach(e => {
          const o = [];
          if (i.useObjectManager) e.remove(a); else {
            let t;
            const n = function (t) {
              const e = t.properties.get('markerId');
              a.includes(e) && o.push(t);
            };
            if (e.each) e.each(n), t = e.getLength(); else if (e.getGeoObjects) {
              const r = e.getGeoObjects();
              r.forEach(n), t = r.length;
            }
            t === 0 || t === o.length ? i.$options.static.myMap.geoObjects.remove(e) : o.length && o.forEach(t => e.remove(t));
          }
        }), this.$emit('markers-was-delete', a);
      }
    }, watch: {
      coordinates(t) {
        this.disablePan ? this.$options.static.myMap.setCenter && this.$options.static.myMap.setCenter(t) : this.$options.static.myMap.panTo && this.$options.static.myMap.getZoom() && this.$options.static.myMap.panTo(t, {checkZoomRange: !0});
      }, zoom() {
        this.$options.static.myMap.setZoom(this.zoom);
      }, bounds(t) {
        this.$options.static.myMap.setBounds && this.$options.static.myMap.setBounds(t);
      }
    }, render(t) {
      const e = typeof t === 'function' ? t : o.h;
      return e('section', {
        class: 'ymap-container',
        ref: 'mapContainer'
      }, [e('div', typeof t === 'function' ? {
        attrs: {
          id: this.ymapId,
          class: this.ymapClass,
          style: this.style
        }
      } : {
        id: this.ymapId,
        class: this.ymapClass,
        style: this.style
      }), this.isReady && e('div', [typeof this.$slots.default === 'function' ? this.$slots.default() : this.$slots.default])]);
    }, mounted() {
      const t = this;
      if (this.$attrs['map-link'] || this.$attrs.mapLink) throw new Error('Vue-yandex-maps: Attribute mapLink is not supported. Use settings.');
      if (this.placemarks && this.placemarks.length) throw new Error('Vue-yandex-maps: Attribute placemarks is not supported. Use marker component.');
      this.mapObserver = new MutationObserver(() => {
        t.$options.static.myMap.container && t.$options.static.myMap.container.fitToViewport();
      });
      const e = this.$refs.mapContainer;
      if (this.mapObserver.observe(e, {
        attributes: !0,
        childList: !0,
        characterData: !0,
        subtree: !1
      }), d.scriptIsNotAttached) {
        const o = this.debug;
        l(function (r) {
          for (let t = 1; t < arguments.length; t++) {
            var a = arguments[t] != null ? arguments[t] : {};
            let e = Object.keys(a);
            typeof Object.getOwnPropertySymbols === 'function' && (e = e.concat(Object.getOwnPropertySymbols(a).filter(t => Object.getOwnPropertyDescriptor(a, t).enumerable))), e.forEach(t => {
              let e;
              let o;
              let n;
              e = r, n = a[o = t], o in e ? Object.defineProperty(e, o, {
                value: n,
                enumerable: !0,
                configurable: !0,
                writable: !0
              }) : e[o] = n;
            });
          }
          return r;
        }({}, this.$options.pluginOptions, this.settings, {debug: o}));
      }
      d.ymapReady ? ymaps.ready(this.init) : d.$on('scriptIsLoaded', this.init);
    }, beforeDestroy() {
      this.$options.static.myMap.geoObjects && this.$options.static.myMap.geoObjects.removeAll();
    }, beforeUnmount() {
      this.$options.static.myMap.geoObjects && this.$options.static.myMap.geoObjects.removeAll();
    }
  };
  const y = ['placemark', 'polyline', 'rectangle', 'polygon', 'circle'];
  const b = ['balloonclose', 'balloonopen', 'click', 'contextmenu', 'dblclick', 'drag', 'dragend', 'dragstart', 'hintclose', 'hintopen', 'mouseenter', 'mouseleave', 'geometrychange', 'overlaychange'];
  const v = {
    inject: ['useObjectManager', 'addMarker', 'deleteMarker', 'compareValues', 'makeComponentBalloonTemplate'],
    props: {
      coords: Array,
      hintContent: String,
      icon: Object,
      balloon: Object,
      markerType: {
        type: String, validator(t) {
          return y.includes(t.toLowerCase());
        }, default: 'placemark'
      },
      markerFill: Object,
      markerStroke: Object,
      clusterName: [String, Number],
      circleRadius: {
        validator(t) {
          return !Number.isNaN(t);
        }, default: 1e3
      },
      balloonTemplate: String,
      markerId: {type: [String, Number], required: !0},
      properties: Object,
      options: Object,
      balloonComponentProps: {
        type: Object, default() {
          return {};
        }
      },
      markerEvents: {
        type: Array, default() {
          return [];
        }
      }
    },
    data() {
      return {unwatchArr: []};
    },
    render(t) {
      const e = typeof t === 'function' ? t : o.h;
      return this.$slots.balloon && e('div', {style: 'display: none;'}, [typeof this.$slots.balloon === 'function' ? this.$slots.balloon() : this.$slots.balloon]);
    },
    mounted() {
      const o = this;
      Object.keys(this.$props).forEach(t => {
        t !== 'balloonComponentProps' && o.unwatchArr.push(o.$watch(t, (t, e) => o.compareValues({
          newVal: t,
          oldVal: e,
          marker: o.defineMarker()
        })));
      }), this.addMarker(this.defineMarker());
    },
    methods: {
      defineMarker() {
        const o = this;
        const t = {
          markerId: this.markerId,
          markerType: this.markerType || 'placemark',
          coords: (function e(t) {
            return t.map(t => (Array.isArray(t) ? e(t) : +t));
          }(this.coords)),
          hintContent: this.hintContent,
          markerFill: this.markerFill,
          circleRadius: +this.circleRadius,
          clusterName: this.clusterName,
          markerStroke: this.markerStroke,
          balloon: this.balloon,
          properties: this.properties,
          options: this.options,
          balloonOptions: {}
        };
        let e = null;
        this.balloonTemplate && (e = ymaps.templateLayoutFactory.createClass(this.balloonTemplate)), this.$slots.balloon && (e = ymaps.templateLayoutFactory.createClass(typeof this.$slots.balloon === 'function' ? this.$slots.balloon()[0].elm.outerHTML : this.$slots.balloon[0].elm.outerHTML)), this.makeComponentBalloonTemplate && (e = this.makeComponentBalloonTemplate(this, t)), e != null && (t.balloonOptions.balloonContentLayout = e), this.icon && ['default#image', 'default#imageWithContent'].includes(this.icon.layout) ? (t.iconContent = this.icon.content, t.iconLayout = this.icon.layout, t.iconImageHref = this.icon.imageHref, t.iconImageSize = this.icon.imageSize, t.iconImageOffset = this.icon.imageOffset, t.iconContentOffset = this.icon.contentOffset, this.icon.contentLayout && typeof this.icon.contentLayout === 'string' && (t.iconContentLayout = ymaps.templateLayoutFactory.createClass(this.icon.contentLayout))) : t.icon = this.icon;
        const n = (function (t, e) {
          const o = m(t);
          if (!e) return o;
          switch (o) {
            case 'Placemark':
              return 'Point';
            case 'Polyline':
              return 'LineString';
            default:
              return o;
          }
        }(t.markerType, this.useObjectManager));
        const r = {
          hintContent: t.hintContent,
          iconContent: t.icon ? t.icon.content : t.iconContent,
          markerId: t.markerId
        };
        const a = t.balloon ? {
          balloonContentHeader: t.balloon.header,
          balloonContentBody: t.balloon.body,
          balloonContentFooter: t.balloon.footer
        } : {};
        const i = Object.assign(r, a, t.properties);
        const s = t.iconLayout ? {
          iconLayout: t.iconLayout,
          iconImageHref: t.iconImageHref,
          iconImageSize: t.iconImageSize,
          iconImageOffset: t.iconImageOffset,
          iconContentOffset: t.iconContentOffset,
          iconContentLayout: t.iconContentLayout
        } : {
          preset: t.icon && 'islands#'.concat((function (t) {
            return (t.icon.color || 'blue') + (t.icon.glyph ? m(t.icon.glyph) : t.icon.content ? 'Stretchy' : '');
          }(t)), 'Icon')
        };
        const c = t.markerStroke ? {
          strokeColor: t.markerStroke.color || '0066ffff',
          strokeOpacity: parseFloat(t.markerStroke.opacity) >= 0 ? parseFloat(t.markerStroke.opacity) : 1,
          strokeStyle: t.markerStroke.style,
          strokeWidth: parseFloat(t.markerStroke.width) >= 0 ? parseFloat(t.markerStroke.width) : 1
        } : {};
        const l = t.markerFill ? {
          fill: t.markerFill.enabled || !0,
          fillColor: t.markerFill.color || '0066ff99',
          fillOpacity: parseFloat(t.markerFill.opacity) >= 0 ? parseFloat(t.markerFill.opacity) : 1,
          fillImageHref: t.markerFill.imageHref || ''
        } : {};
        const u = Object.assign(s, c, l, t.balloonOptions, t.options);
        n === 'Circle' && (t.coords = [t.coords, t.circleRadius]);
        const p = (function (t, e) {
          const o = e ? {
            type: 'Feature',
            id: t.properties.markerId,
            geometry: {type: t.markerType, coordinates: t.coords},
            properties: t.properties,
            options: t.options
          } : new ymaps[t.markerType](t.coords, t.properties, t.options);
          return o.clusterName = t.clusterName, o;
        }({
          properties: i,
          options: u,
          markerType: n,
          coords: t.coords,
          clusterName: t.clusterName
        }, this.useObjectManager, this.$emit));
        this.useObjectManager || (this.markerEvents.length ? this.markerEvents : b).forEach(e => p.events.add(e, t => o.$emit(e, t)));
        return this.$emit('target', p), p;
      }
    },
    beforeDestroy() {
      this.unwatchArr.forEach(t => t()), this.deleteMarker(this.markerId);
    }
  };
  h.install = function t(e) {
    const o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    t.installed || (t.installed = !0, (function (t) {
      typeof t.extend === 'function' && (u = t.extend({
        props: ['marker', 'component'],
        template: '<component :is="component" v-bind="{ marker, ...props.balloonComponentProps }" v-on="listeners" />'
      }));
    }(e)), h.pluginOptions = o, e.component('yandex-map', h), e.component('ymap-marker', v));
  }, typeof window !== 'undefined' && window.Vue && window.Vue.use(h);
  const k = l;
  const C = h;
  const O = v;
  t.loadYmap = k, t.yandexMap = C, t.ymapMarker = O, t.default = h, Object.defineProperty(t, '__esModule', {value: !0});
}));
