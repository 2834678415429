<template>
  <div>
    <div
      class="ri-map-balloon pointer"
      @click="link()"
    >
      <div class="ri-map-balloon__info">
        <p class="roquefort mt-3">
          {{ marker.titles.text }}
        </p>
        <p class="mt-1 mb-5">
          {{ marker.titles.address }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    link() {
      const _this = this;
      const r = this.$router.resolve({
        name: 'edit-institution',
        params: { id: _this.marker.id }
      });
      window.location.assign(r.href);
    }
  }
};
</script>

<style lang="scss" scoped>
.ri-map-balloon {
  min-width: 220px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
<style>
.roquefort {
  font-size: 13px;
  line-height: 16px;
}
.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.leaflet-container a {
  color: unset !important;
}
.leaflet-popup-tip-container {
  display: none;
}
.leaflet-popup-content {
  width: unset !important;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}
.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup-content p {
  margin: 4px 0 !important;
}
</style>
