<template>
  <div class="objects">
    <loadCard
      :list-item="listItem"
    />
  </div>
</template>

<script>

import loadCard from '@/components/loadCard.vue';

export default {
  name: 'DragDropTable',
  components: { loadCard },
  props: {
    listItem: {
      type: [Array, Object],
      default: () => []
    }
  }
};
</script>

<style scoped lang="scss">
.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
}

.table_head {
  width: max-content;
  min-width: 100%;
  border-bottom: 1px solid #e4edfb;
  .table_row {
    border: none;
    padding-top: 0;
  }
}

.table_row {
  display: grid !important;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #E4EDFB;
  position: relative;

  &.hidden {
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F6F9FE;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: .2s ease-in-out;
    transition-delay: .3s;
    cursor: pointer;
    opacity: 0;

    .rir-icon {
      transition: .2s ease-in-out;
    }

    &:hover {
      .rir-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}
::v-deep .scrollbar-track {
  position: static;
}
::v-deep .scrollbar-track-x {
  background: #F6F9FE;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 28px;

  .scrollbar-thumb {
    background: #E4EDFB;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}
.text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.72;
  float: left;
  cursor: pointer;
}
.text__name {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}
.hover_alt {
  background-color: white;
}
.hover_alt:hover {
  background-color: #F6F9FE;
}
</style>
